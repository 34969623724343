/*

--- 01 TYPOGRAPHY SYSTEM

-Spacyng System (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

-Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

-Font weights
Default: 400
Medium: 500 ---> strong
Semi-bold: 600
Bold: 700

-Line Heights
Default: 1
Secondary Titles: 1.2
Primary Titles: 1.05
Paragraph: 1.6
Large: 1.8

--- 02 COLORS

-Primary: #339af0
-Tints: 
#e7f5ff
#228be6
#1971c2
#1864ab
-Shades: 
-Accents:
#ffe066
-Greys: 
#888
#767676 lightness grey allow with #fff
#555 
#333
#6f6f6f lightness grey allow in #fdf2e9

--- 03 LETER SPACE
Default: 1
Primary Titles: -0.5
Subheading: 0.75


--- 05 SHADOWS
box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.75);
box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 1.5);

--- 06 BORDER RADIUS
Default: 9px
Cards: 11px



*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 10 px; Not adapted to web browser*/
  /* 10px/16px=0.625 16px is browser font size and 10px the scale we wan to use for 1 rem*/
  /* percentage of user's browser font-size setting */
  /* 1 rem */
  font-size: 62.5%;
  /* para efectos de transiciones */
  overflow-x: hidden;
  /* Does not work on Safari */
  scroll-behavior: smooth;
}

/* PAGE SECTIONS */
body {
  font-family: Inter, sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #555;
  /* para efectos de transiciones */
  overflow-x: hidden;
}

.container {
  /* 1140px */
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

/*******************************/
/* GRIDS */
/*******************************/

.grid {
  display: grid;
  grid-row-gap: 9.6rem;
  row-gap: 9.6rem;
  grid-column-gap: 6.4rem;
  -webkit-column-gap: 6.4rem;
          column-gap: 6.4rem;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

/*******************************/
/* HEADINGS*/
/*******************************/
.heading-primary {
  color: #333;
  font-size: 4.4rem;
  line-height: 1.05;
  letter-spacing: -0.5;
}

.heading-secondary {
  color: #333;
  font-size: 3.6rem;
  line-height: 1.2;
}

.subheading {
  text-transform: uppercase;
  color: #1971c2;
  font-size: 1.2rem;
  letter-spacing: 0.75;
  font-weight: 800;
}

/*******************************/
/* BUTTONS */
/*******************************/

.btn:link,
.btn:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 2rem;
  padding: 1.6rem 3.2rem;
  background-color: #1971c2;
  color: #fff;
  border-radius: 9px;
  transition-timing-function: ease-in;
  transition-duration: all 0.3s;
}

.btn:hover,
.btn:active {
  background-color: #1864ab;
}

/*******************************/
/* QUOTES */
/*******************************/

/*******************************/
/* AUX */
/*******************************/

.margin-right-sm {
  margin-right: 1.6rem;
}

.margin-bottom-sm {
  margin-bottom: 9.6rem;
}

/*******************************/
/* -Spacyng System (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

-Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98 */

/* HEADER  */
/*******************************/

.header {
  background-color: #e7f5ff;
  height: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* mobile-nav */
  position: relative;
}

.logo-header {
  display: block;
  margin: auto;
  height: 8rem;
  width: 20rem;
}

/*******************************/
/* NAVEGATION  */
/*******************************/
.main-nav-list {
  display: flex;
  justify-content: flex-start;
  grid-gap: 3.2rem;
  gap: 3.2rem;
  padding: 0 3.2rem 0 0;
  list-style: none;
}

.main-nav-link:link,
.main-nav-link:visited {
  text-decoration: none;
  color: #555;
  font-size: 1.8rem;
  font-weight: 500;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: #1864ab;
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
  cursor: pointer;
}

.nav-cta:link,
.nav-cta:visited {
  padding: 1.2rem 2.4rem;
  background-color: #228be6;
  color: #fff;
  border-radius: 9px;
}

.nav-cta:hover,
.nav-cta:active {
  background-color: #1864ab;
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
}

/* STICKY */
.sticky .header {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 8rem;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 999;
  padding-bottom: 0;
  padding-top: 0;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
}

.sticky .section-hero {
  margin-top: 9.6rem;
}

/* MOBILE NAV */

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
  display: none;
}

.mobile-nav-icon {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.mobile-nav-icon[name="close-outline"] {
  display: none;
}

/*******************************/
/* HERO SECTION  */
/*******************************/

.section-hero {
  padding: 4.8rem 0 9.6rem 0;
  background-color: #e7f5ff;
}

.hero {
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 4.8rem;
  gap: 0 4.8rem;
  justify-items: center;
  align-items: center;
  max-width: 130rem;
}

.hero-title {
  margin-bottom: 2.4rem;
}

.hero-text {
  margin-bottom: 6.4rem;
  font-size: 2rem;
  line-height: 1.6;
  color: #555;
}

.btn-about-us:link,
.btn-about-us:visited {
  background-color: #fff;
  color: #555;
}

.btn-about-us:hover,
.btn-about-us:active {
  background-color: #e7f5ff;
  box-shadow: inset 0 0 0 2px #fff;
  transition-timing-function: ease-in;
  transition-duration: 0.3s;
}

.btn-cta:link,
.btn-cta:visited {
  background-color: #1971c2;
  color: #fff;
}

.btn-cta:hover,
.btn-cta:active {
  background-color: #1864ab;
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
}
.hero-img {
  width: 100%;
  border-radius: 8%;
}

.hero-img-box {
  background-image: linear-gradient(to right bottom, #36506988, #f09b3360),
    url(/static/media/hero.04bee920.webp);
  background-size: cover;
  background-position: center;
}

/*******************************/
/* SERVICES */
/*******************************/

.section-services {
  margin: 9.6rem 0;
  scroll-behavior: smooth;
}

.services-titles {
  margin-bottom: 4.8rem;
}

.service-plan {
  background-color: #fff;
  border: solid 2px #e7f5ff;
  padding: 7.8rem 3.2rem;
  border-radius: 11px;
  width: 75%;
  position: relative;
}

.service-plan:hover {
  -webkit-transform: translate(0rem, -1.2rem);
          transform: translate(0rem, -1.2rem);
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
}

.premium {
  background-color: #e7f5ff;
  overflow: hidden;
}

.premium::before {
  position: absolute;
  content: "M\E1s vendido";
  text-transform: uppercase;
  background-color: #ffe066;
  font-weight: 600;
  font-size: 1.8rem;
  top: 8%;
  right: -11%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  padding: 0.4rem 3.2rem;
  letter-spacing: -0.75;
}

.service-title {
  font-size: 2.4rem;
  font-weight: 600;
  padding-bottom: 1.8rem;
  color: #1971c2;
  text-transform: uppercase;
}

.service-description {
  font-size: 2.4rem;
  margin-bottom: 0.8rem;
}

.service-item {
  font-size: 1.8rem;
}

.service-item:not(:last-child) {
  margin-bottom: 1.2rem;
}

.btn-services {
  position: absolute;
  top: 87%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/*******************************/
/* Projects  */
/*******************************/

.section-projects {
  scroll-behavior: smooth;
  padding: 9.6rem 0;
  background-color: #e7f5ff;
}

.projects-titles {
  margin-bottom: 4.8rem;
}

.projects-subheading {
  margin-bottom: 1.6rem;
}

.slider-projects {
  display: grid;
  grid-template-rows: 19fr 1fr;
  grid-gap: 3rem;
  gap: 3rem;
}

.arrow-slider-projects {
  grid-column: start/end;
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  position: relative;
}

.arrow-left-slider-projects {
  grid-column: 1;
  /* justify-self: center; */
  align-self: center;
  /* Arrow slider height: 527px -> 263.5px -> 16.47rem */
  position: absolute;
  top: 320px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.arrow-right-slider-projects {
  grid-column: 3;
  align-self: center;
  /* Arrow slider height: 527px -> 263.5px -> 16.47rem */
  position: absolute;
  top: 320px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

.btn-arrow-projects {
  background-color: transparent;
  border: none;
  border-radius: 100%;
  padding: -0.2rem -0.2rem;
}

.btn-arrow-projects:hover .arrow-icon-projects {
  color: #333;
  box-shadow: 0 0 3px 2px rgb(253 242 233 / 58%);
  transition-timing-function: ease-in;
  transition-duration: 0.1s;
  cursor: pointer;
}

.arrow-icon-projects {
  height: 4rem;
  width: 4rem;
  color: #888;
  border-radius: 100%;
}

.slide-container-projects {
  grid-column: 2;
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-columns: 1;
  grid-gap: 4rem 0;
  gap: 4rem 0;
}

.dot-slider-projects {
  grid-column: start/end;
  grid-row: 2;
  justify-self: center;
  display: flex;
  grid-gap: 1.4rem;
  gap: 1.4rem;
}

.btn-dot-projects {
  border: none;
  background-color: transparent;
}

.dot-projects {
  height: 1.4rem;
  width: 1.4rem;
  color: #888;
  border-radius: 100%;
}

.dot-projects:hover {
  transition-timing-function: ease-in;
  transition-duration: 0.1s;
  cursor: pointer;
}

.dot-active-projects {
  color: #888;
  background-color: #888;
}

.project {
  margin-left: 0rem;
  display: flex;
  align-items: center;
}

.project-img {
  border-radius: 10%;
  display: block;
  width: 50rem;
  height: 50rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.2rem;
  width: 80%;
}

.project-name {
  font-size: 2.4rem;
  color: #666;
}

.project-description {
  font-size: 1.8rem;
  color: #555;
  margin-bottom: 1.2rem;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  position: relative;
  padding: 1.2rem 3rem;
}

/*******************************/
/* CTA */
/*******************************/

.cta {
  padding: 9.6rem 0;
  scroll-behavior: smooth;
}

.cta-title {
  color: #fff;
  margin-bottom: 1.8rem;
}

.form-box-cta {
  background-color: #1864ab;
  color: #fff;
  padding: 4.8rem 6.4rem 6.4rem 6.4rem;
  font-size: 1.8rem;
  line-height: 1.6;
}

.cta-text {
  margin-bottom: 3.2rem;
}

.cta-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-radius: 11px;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0 2.4rem 4.8rem 0rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.cta-img-box {
  background-image: linear-gradient(to right bottom, #1970c288, #339bf060),
    url(/static/media/cta_contact_resize.02f1372c.jpeg);
  background-size: cover;
  background-position: center;
}

.cta-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.8rem 1.8rem;
  gap: 1.8rem 1.8rem;
}

.cta-form input,
select {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.6rem;
  font-family: inherit;
  color: #333;
  background-color: #fff;
  border: none;
  border-radius: 9px;
  text-align: start;
  display: block;
  /* -webkit-appearance: none; */
}

.cta-form input::-webkit-input-placeholder {
  /* texto de ejemplo en los campos input */
  color: #888;
  font-size: 1.6rem;
  font-family: inherit;
  height: 100%;
  position: absolute;
}

.cta-form input::placeholder {
  /* texto de ejemplo en los campos input */
  color: #888;
  font-size: 1.6rem;
  font-family: inherit;
  height: 100%;
  position: absolute;
}

.cta-form *:focus {
  /* foco en el campo seleccionado */
  outline: none;
  box-shadow: 0 0 4px 3px rgb(253 242 233 / 58%);
}

.cta-textbox {
  grid-column: 1/-1;
  height: 18rem;
  border-radius: 11px;
}

.cta-textbox-input {
  height: 90%;
  width: 100%;
  border-radius: 11px;
  padding: 2px 2px;
  color: #888;
  font-size: 1.6rem;
  font-family: inherit;
}

.cta-textbox textarea {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.6rem;
  font-family: inherit;
  color: #333;
  background-color: #fff;
  border: none;
  border-radius: 9px;
  text-align: start;
  display: block;
  resize: vertical;
  height: 14rem;
}

.btn--form {
  margin-top: 1.2rem;
  padding: 1.2rem 2.4rem;
  font-size: 1.8rem;
  border-radius: 11px;
  background-color: #eee;
  color: #333;
  width: end;
  border: none;
  grid-column: 1/-1;
}

.btn--form:hover,
.btn--form:active {
  background-color: #1864ab;
  color: #fff;
  box-shadow: 0 0 3px 2px rgb(253 242 233 / 58%);
  transition-timing-function: ease-in;
  transition-duration: 0.1s;
  cursor: pointer;
}

/*******************************/
/* FOOTER */
/*******************************/

.footer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 4.8rem;
  gap: 4.8rem;
}

footer {
  padding: 9.8rem 0;
  border-top: solid 1px #eee;
}

.logo-ft {
  height: 14rem;
  width: 20rem;
  align-self: flex-start;
  justify-self: start;
}

.footer-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1.8rem;
}

.social-media-list {
  list-style: none;
  display: flex;
  grid-gap: 1.8rem;
  gap: 1.8rem;
  align-items: center;
  margin-bottom: 1.8rem;
}

.media-icon {
  color: #666;
  height: 2.4rem;
  width: 2.4rem;
}

.copyright {
  color: #666;
}

.contact-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  grid-gap: 1.2rem;
  gap: 1.2rem;
}

.contact-item {
  display: flex;
  align-items: center;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  font-size: 1.2rem;
}

.contact-link:link,
.contact-link:visited {
  text-decoration: none;
  color: #666;
}
.contact-link:hover,
.contact-link:active {
  color: #333;
}

.contact-icon {
  height: 2.4rem;
  width: 2.4rem;
  color: #666;
}

.company-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  grid-gap: 1.2rem;
  gap: 1.2rem;
}

.company-item {
  font-size: 1.2rem;
}

.footer-link-company:link,
.footer-link-company:visited {
  display: block;
  text-decoration: none;
  color: #666;
}

.footer-link-company:hover,
.footer-link-company:active {
  color: #666;
}

/* -Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98 */
/* -Spacyng System (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

/* rem and em do not depend on html font-size in media queries. 
Instead, 1rem=1em=16px */

/*******************************/
/* BELOW 1344px (Smaller Desktops)  */
/*******************************/

@media (max-width: 84em) {
  .hero {
    max-width: 120rem;
  }
}
/*******************************/
/* BELOW 1110px (Landscape Tablets)  */
/*******************************/

@media (max-width: 68em) {
  .service-title {
    font-size: 2.2rem;
  }

  .service-description {
    font-size: 2rem;
    margin-bottom: 0.8rem;
  }

  .btn-services {
    position: absolute;
    top: 84%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  a.btn.btn-services {
    font-size: 1.6rem;
  }

  .premium::before {
    position: absolute;
    content: "M\E1s vendido";
    text-transform: uppercase;
    background-color: #ffe066;
    font-weight: 600;
    font-size: 1.2rem;
    top: 8.5%;
    right: -10%;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    padding: 0.4rem 3.2rem;
    letter-spacing: -0.75;
  }
  .cta-form label {
    font-size: 1.6rem;
  }

  .cta-form input,
  select {
    font-size: 1.6rem;
  }
}

/*******************************/
/* BELOW 970px (Tablets)  */
/*******************************/
@media (max-width: 61em) {
  .nav-list {
    grid-gap: 2.4rem;
    gap: 2.4rem;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .btn-services {
    position: absolute;
    top: 87%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  a.btn.btn-services {
    font-size: 1.6rem;
  }

  .btn:link,
  .btn:visited {
    font-size: 1.8rem;
    padding: 1.2rem 2.4rem;
  }
  .hero-title {
    font-size: 3.6rem;
  }
  .hero-text {
    font-size: 1.6rem;
    line-height: 1.2;
  }

  .heading-secondary {
    font-size: 3rem;
  }

  .grid--2-cols {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 4.8rem;
    row-gap: 4.8rem;
  }

  .margin-bottom-sm {
    margin-bottom: 4.8rem;
  }

  .service-plan {
    width: 50%;
    padding: 4.8rem 1.2rem;
    justify-self: center;
  }

  .premium::before {
    position: absolute;
    content: "M\E1s vendido";
    text-transform: uppercase;
    background-color: #ffe066;
    font-weight: 600;
    font-size: 0.9rem;
    top: 6%;
    right: -10%;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    padding: 0.4rem 3.2rem;
    letter-spacing: -0.75;
  }

  .cta-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .join-us-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

/*******************************/
/* BELOW 800px (small-Tablets)  */
/*******************************/

@media (max-width: 50em) {
  .nav-list {
    grid-gap: 1.2rem;
    gap: 1.2rem;
  }

  .main-nav-list {
    grid-gap: 1.2rem;
    gap: 1.2rem;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .btn:link,
  .btn:visited {
    font-size: 1.8rem;
    padding: 1.2rem 2.2rem;
  }

  .hero {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    grid-row-gap: 0rem;
    row-gap: 0rem;
  }
  .img-text-box {
    grid-row: 1;
  }

  .img-hero-box {
    grid-row: 2;
    width: 75%;
    height: 75%;
  }
  .hero-title {
    font-size: 3rem;
  }

  .hero-text {
    font-size: 1.8rem;
    line-height: 1.2;
    margin-bottom: 2.4rem;
  }

  .heading-secondary {
    font-size: 2.4rem;
  }

  .arrow-left-slider-projects {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  .arrow-right-slider-projects {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  .footer-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  footer {
    padding: 4rem 0;
    border-top: solid 1px #eee;
  }

  .footer-title {
    font-size: 1.4rem;
  }

  .contact-icon {
    height: 2rem;
    width: 2rem;
  }

  .copyright {
    display: none;
  }

  .contact-item {
    font-size: 1rem;
  }

  .company-item {
    font-size: 1rem;
  }

  .footer-company {
    display: none;
  }
}
/*******************************/
/* BELOW 672px (Phones)  */
/*******************************/

@media (max-width: 42em) {
  .service-plan {
    width: 65%;
    padding: 4.8rem 1.2rem;
    justify-self: center;
  }

  .arrow-left-slider-projects {
    -webkit-transform: translateY(600%);
            transform: translateY(600%);
  }
  .arrow-right-slider-projects {
    -webkit-transform: translateY(600%);
            transform: translateY(600%);
  }

  .cta-form label {
    font-size: 1.2rem;
  }

  .cta-form input,
  select {
    font-size: 1.2rem;
  }

  .footer-grid {
    grid-gap: 2.4rem;
    gap: 2.4rem;
  }

  /*////////// MOBILE-NAV /////////*/

  .btn-mobile-nav {
    display: block;
    z-index: 999;
  }

  .main-nav {
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    /* relative to header */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    /* efecto de que venga desde la derecha  */
    transition: all 2 ease-in-out;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);

    /* blur effect in mobile nav, webkit for safari, it doesn't work for firefox*/
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

    /* display none doesn't allow animations
    display: none; */
    /* 1)hide element visually */
    opacity: 0;
    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;
    /* 3)Hide it from screen readers */
    visibility: hidden;
  }

  .mobile-nav-visible .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  .main-nav-list {
    display: flex;
    flex-direction: column;
    grid-gap: 4.8rem;
    gap: 4.8rem;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 2.4rem;
  }

  .mobile-nav-visible .mobile-nav-icon[name="close-outline"] {
    display: block;
  }

  .mobile-nav-visible .mobile-nav-icon[name="menu-outline"] {
    display: none;
  }

  .dot-slider-projects {
    /* display none doesn't allow animations
    display: none; */
    /* 1)hide element visually */
    opacity: 0;
    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;
    /* 3)Hide it from screen readers */
    visibility: hidden;
    display: none;
  }

  .arrow-left-slider-projects {
    /* Arrow slider height: 185px -> 92.5px -> 11.56rem */
    position: absolute;
    top: 92.5px;
  }
  .arrow-right-slider-projects {
    /* Arrow slider height: 185px -> 92.5px -> 11.56rem */
    position: absolute;
    top: 92.5px;
  }
}

/*******************************/
/* BELOW 576px (Phones)  */
/*******************************/
@media (max-width: 36em) {
  .container {
    padding: 0 1.6rem;
  }

  .hero {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .heading-secondary {
    font-size: 1.8rem;
  }

  .subheading {
    font-size: 1.2rem;
  }

  .service-plan {
    background-color: #fff;
    border: solid 2px #e7f5ff;
    padding: 4.8rem 2.4rem;
    border-radius: 11px;
    width: 80%;
    position: relative;
  }

  .service-title {
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    padding-bottom: 2.8rem;
    color: #1971c2;
    text-transform: uppercase;
  }

  .service-description {
    display: none;
  }

  .btn-services {
    position: absolute;
    top: 65%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  a.btn.btn-services {
    font-size: 1.4rem;
  }

  .btn:link,
  .btn:visited {
    font-size: 1.4rem;
    padding: 1.2rem 1.8rem;
  }

  .premium::before {
    font-size: 1rem;
    top: 13%;
    right: -10%;
    letter-spacing: -0.75;
  }

  .premium {
    background-color: #e7f5ff;
    overflow: hidden;
  }

  .project-plan {
    width: 90%;
  }

  .arrow-slider-projects {
    grid-column: start/end;
    grid-row: 1;
    display: grid;
    grid-template-columns: 1fr 20fr 1fr;
    position: relative;
  }

  .arrow-left-slider-projects {
    position: absolute;
    top: 92.5px;
    /* Arrow slider height: 527px -> 263.5px -> 16.47rem */
  }

  .arrow-right-slider-projects {
    position: absolute;
    top: 92.5px;
    right: 4px;
    /* Arrow slider height: 527px -> 263.5px -> 16.47rem */
  }

  .form-box-cta {
    padding: 2.4rem 1.2rem;
  }

  .cta-text {
    font-size: 1.4rem;
  }

  .cta-form label {
    font-size: 1rem;
  }

  .cta-form input,
  select {
    font-size: 1.4rem;
  }

  .cta-form input::-webkit-input-placeholder {
    /* texto de ejemplo en los campos input */
    font-size: 1.4rem;
  }

  .cta-form input::placeholder {
    /* texto de ejemplo en los campos input */
    font-size: 1.4rem;
  }

  .footer-grid {
    grid-column: 1fr 1fr;
    grid-gap: 0rem;
    gap: 0rem;
  }

  .footer-social-media {
    display: none;
  }
}

/*******************************/
/* BELOW 408px (Phones)  */
/*******************************/

@media (max-width: 25.5em) {
  .hero-title {
    font-size: 2.8rem;
  }
  .hero-text {
    font-size: 1.6rem;
    margin-bottom: 2.4rem;
  }

  .btn:link,
  .btn:visited {
    font-size: 1.6rem;
    padding: 0.8rem 1.6rem;
    margin-bottom: 3.6rem;
  }

  .img-hero-box {
    display: block;
    width: 100%;
    height: 100%;
    margin-bottom: 0rem;
  }
  */ .service-plan {
    width: 95%;
  }

  .arrow-slider-projects {
    grid-column: start/end;
    grid-row: 1;
    display: grid;
    grid-template-columns: 1fr 20fr 1fr;
    position: relative;
  }

  .arrow-left-slider-projects {
    position: absolute;
    top: 200px;
    /* Arrow slider height: 527px -> 263.5px -> 16.47rem */
  }

  .arrow-right-slider-projects {
    position: absolute;
    top: 200px;
    right: 4px;
    /* Arrow slider height: 527px -> 263.5px -> 16.47rem */
  }

  .arrow-icon-projects {
    height: 3rem;
    width: 3rem;
  }

  .footer-grid {
    display: none;
  }
}

/*******************************/
/* BELOW 368px (Phones)  */
/*******************************/

@media (max-width: 23em) {
  .arrow-slider-projects {
    grid-column: start/end;
    grid-row: 1;
    display: grid;
    grid-template-columns: 1fr 30fr 1fr;
    position: relative;
  }

  .arrow-icon-projects {
    height: 3rem;
    width: 3rem;
  }

  .arrow-left-slider-projects {
    position: absolute;
    top: 230px;
    /* Arrow slider height: 527px -> 263.5px -> 16.47rem */
  }

  .arrow-right-slider-projects {
    position: absolute;
    top: 230px;
    right: 4px;
    /* Arrow slider height: 527px -> 263.5px -> 16.47rem */
  }
}

