/*******************************/
/* -Spacyng System (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

-Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98 */

/* HEADER  */
/*******************************/

.header {
  background-color: #e7f5ff;
  height: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* mobile-nav */
  position: relative;
}

.logo-header {
  display: block;
  margin: auto;
  height: 8rem;
  width: 20rem;
}

/*******************************/
/* NAVEGATION  */
/*******************************/
.main-nav-list {
  display: flex;
  justify-content: flex-start;
  gap: 3.2rem;
  padding: 0 3.2rem 0 0;
  list-style: none;
}

.main-nav-link:link,
.main-nav-link:visited {
  text-decoration: none;
  color: #555;
  font-size: 1.8rem;
  font-weight: 500;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: #1864ab;
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
  cursor: pointer;
}

.nav-cta:link,
.nav-cta:visited {
  padding: 1.2rem 2.4rem;
  background-color: #228be6;
  color: #fff;
  border-radius: 9px;
}

.nav-cta:hover,
.nav-cta:active {
  background-color: #1864ab;
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
}

/* STICKY */
.sticky .header {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 8rem;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 999;
  padding-bottom: 0;
  padding-top: 0;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
}

.sticky .section-hero {
  margin-top: 9.6rem;
}

/* MOBILE NAV */

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
  display: none;
}

.mobile-nav-icon {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.mobile-nav-icon[name="close-outline"] {
  display: none;
}

/*******************************/
/* HERO SECTION  */
/*******************************/

.section-hero {
  padding: 4.8rem 0 9.6rem 0;
  background-color: #e7f5ff;
}

.hero {
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 4.8rem;
  justify-items: center;
  align-items: center;
  max-width: 130rem;
}

.hero-title {
  margin-bottom: 2.4rem;
}

.hero-text {
  margin-bottom: 6.4rem;
  font-size: 2rem;
  line-height: 1.6;
  color: #555;
}

.btn-about-us:link,
.btn-about-us:visited {
  background-color: #fff;
  color: #555;
}

.btn-about-us:hover,
.btn-about-us:active {
  background-color: #e7f5ff;
  box-shadow: inset 0 0 0 2px #fff;
  transition-timing-function: ease-in;
  transition-duration: 0.3s;
}

.btn-cta:link,
.btn-cta:visited {
  background-color: #1971c2;
  color: #fff;
}

.btn-cta:hover,
.btn-cta:active {
  background-color: #1864ab;
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
}
.hero-img {
  width: 100%;
  border-radius: 8%;
}

.hero-img-box {
  background-image: linear-gradient(to right bottom, #36506988, #f09b3360),
    url("../img/hero/hero.webp");
  background-size: cover;
  background-position: center;
}

/*******************************/
/* SERVICES */
/*******************************/

.section-services {
  margin: 9.6rem 0;
  scroll-behavior: smooth;
}

.services-titles {
  margin-bottom: 4.8rem;
}

.service-plan {
  background-color: #fff;
  border: solid 2px #e7f5ff;
  padding: 7.8rem 3.2rem;
  border-radius: 11px;
  width: 75%;
  position: relative;
}

.service-plan:hover {
  transform: translate(0rem, -1.2rem);
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
}

.premium {
  background-color: #e7f5ff;
  overflow: hidden;
}

.premium::before {
  position: absolute;
  content: "Más vendido";
  text-transform: uppercase;
  background-color: #ffe066;
  font-weight: 600;
  font-size: 1.8rem;
  top: 8%;
  right: -11%;
  transform: rotate(45deg);
  padding: 0.4rem 3.2rem;
  letter-spacing: -0.75;
}

.service-title {
  font-size: 2.4rem;
  font-weight: 600;
  padding-bottom: 1.8rem;
  color: #1971c2;
  text-transform: uppercase;
}

.service-description {
  font-size: 2.4rem;
  margin-bottom: 0.8rem;
}

.service-item {
  font-size: 1.8rem;
}

.service-item:not(:last-child) {
  margin-bottom: 1.2rem;
}

.btn-services {
  position: absolute;
  top: 87%;
  left: 50%;
  transform: translateX(-50%);
}

/*******************************/
/* Projects  */
/*******************************/

.section-projects {
  scroll-behavior: smooth;
  padding: 9.6rem 0;
  background-color: #e7f5ff;
}

.projects-titles {
  margin-bottom: 4.8rem;
}

.projects-subheading {
  margin-bottom: 1.6rem;
}

.slider-projects {
  display: grid;
  grid-template-rows: 19fr 1fr;
  gap: 3rem;
}

.arrow-slider-projects {
  grid-column: start/end;
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  position: relative;
}

.arrow-left-slider-projects {
  grid-column: 1;
  /* justify-self: center; */
  align-self: center;
  /* Arrow slider height: 527px -> 263.5px -> 16.47rem */
  position: absolute;
  top: 320px;
  transform: translateY(-50%);
  transform: translateX(100%);
}

.arrow-right-slider-projects {
  grid-column: 3;
  align-self: center;
  /* Arrow slider height: 527px -> 263.5px -> 16.47rem */
  position: absolute;
  top: 320px;
  transform: translateY(-50%);
  transform: translateX(50%);
}

.btn-arrow-projects {
  background-color: transparent;
  border: none;
  border-radius: 100%;
  padding: -0.2rem -0.2rem;
}

.btn-arrow-projects:hover .arrow-icon-projects {
  color: #333;
  box-shadow: 0 0 3px 2px rgb(253 242 233 / 58%);
  transition-timing-function: ease-in;
  transition-duration: 0.1s;
  cursor: pointer;
}

.arrow-icon-projects {
  height: 4rem;
  width: 4rem;
  color: #888;
  border-radius: 100%;
}

.slide-container-projects {
  grid-column: 2;
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-columns: 1;
  gap: 4rem 0;
}

.dot-slider-projects {
  grid-column: start/end;
  grid-row: 2;
  justify-self: center;
  display: flex;
  gap: 1.4rem;
}

.btn-dot-projects {
  border: none;
  background-color: transparent;
}

.dot-projects {
  height: 1.4rem;
  width: 1.4rem;
  color: #888;
  border-radius: 100%;
}

.dot-projects:hover {
  transition-timing-function: ease-in;
  transition-duration: 0.1s;
  cursor: pointer;
}

.dot-active-projects {
  color: #888;
  background-color: #888;
}

.project {
  margin-left: 0rem;
  display: flex;
  align-items: center;
}

.project-img {
  border-radius: 10%;
  display: block;
  width: 50rem;
  height: 50rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.2rem;
  width: 80%;
}

.project-name {
  font-size: 2.4rem;
  color: #666;
}

.project-description {
  font-size: 1.8rem;
  color: #555;
  margin-bottom: 1.2rem;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  position: relative;
  padding: 1.2rem 3rem;
}

/*******************************/
/* CTA */
/*******************************/

.cta {
  padding: 9.6rem 0;
  scroll-behavior: smooth;
}

.cta-title {
  color: #fff;
  margin-bottom: 1.8rem;
}

.form-box-cta {
  background-color: #1864ab;
  color: #fff;
  padding: 4.8rem 6.4rem 6.4rem 6.4rem;
  font-size: 1.8rem;
  line-height: 1.6;
}

.cta-text {
  margin-bottom: 3.2rem;
}

.cta-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-radius: 11px;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0 2.4rem 4.8rem 0rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.cta-img-box {
  background-image: linear-gradient(to right bottom, #1970c288, #339bf060),
    url("../img/cta/cta_contact_resize.jpeg");
  background-size: cover;
  background-position: center;
}

.cta-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.8rem 1.8rem;
}

.cta-form input,
select {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.6rem;
  font-family: inherit;
  color: #333;
  background-color: #fff;
  border: none;
  border-radius: 9px;
  text-align: start;
  display: block;
  /* -webkit-appearance: none; */
}

.cta-form input::placeholder {
  /* texto de ejemplo en los campos input */
  color: #888;
  font-size: 1.6rem;
  font-family: inherit;
  height: 100%;
  position: absolute;
}

.cta-form *:focus {
  /* foco en el campo seleccionado */
  outline: none;
  box-shadow: 0 0 4px 3px rgb(253 242 233 / 58%);
}

.cta-textbox {
  grid-column: 1/-1;
  height: 18rem;
  border-radius: 11px;
}

.cta-textbox-input {
  height: 90%;
  width: 100%;
  border-radius: 11px;
  padding: 2px 2px;
  color: #888;
  font-size: 1.6rem;
  font-family: inherit;
}

.cta-textbox textarea {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.6rem;
  font-family: inherit;
  color: #333;
  background-color: #fff;
  border: none;
  border-radius: 9px;
  text-align: start;
  display: block;
  resize: vertical;
  height: 14rem;
}

.btn--form {
  margin-top: 1.2rem;
  padding: 1.2rem 2.4rem;
  font-size: 1.8rem;
  border-radius: 11px;
  background-color: #eee;
  color: #333;
  width: end;
  border: none;
  grid-column: 1/-1;
}

.btn--form:hover,
.btn--form:active {
  background-color: #1864ab;
  color: #fff;
  box-shadow: 0 0 3px 2px rgb(253 242 233 / 58%);
  transition-timing-function: ease-in;
  transition-duration: 0.1s;
  cursor: pointer;
}

/*******************************/
/* FOOTER */
/*******************************/

.footer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 4.8rem;
}

footer {
  padding: 9.8rem 0;
  border-top: solid 1px #eee;
}

.logo-ft {
  height: 14rem;
  width: 20rem;
  align-self: flex-start;
  justify-self: start;
}

.footer-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1.8rem;
}

.social-media-list {
  list-style: none;
  display: flex;
  gap: 1.8rem;
  align-items: center;
  margin-bottom: 1.8rem;
}

.media-icon {
  color: #666;
  height: 2.4rem;
  width: 2.4rem;
}

.copyright {
  color: #666;
}

.contact-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.2rem;
}

.contact-link:link,
.contact-link:visited {
  text-decoration: none;
  color: #666;
}
.contact-link:hover,
.contact-link:active {
  color: #333;
}

.contact-icon {
  height: 2.4rem;
  width: 2.4rem;
  color: #666;
}

.company-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.company-item {
  font-size: 1.2rem;
}

.footer-link-company:link,
.footer-link-company:visited {
  display: block;
  text-decoration: none;
  color: #666;
}

.footer-link-company:hover,
.footer-link-company:active {
  color: #666;
}
